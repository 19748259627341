import React, { Suspense, useContext } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { AuthProvider } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/custom.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Loader from "./components/Loader";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Auth/Home";
import Profile from "./pages/Auth/Profile";
import SignIn from "./pages/Auth/SignIn";
import Error from "./pages/Error";
import {
  CmsManagement,
  CustomerView,
  Customer,
  Notifications,
  Order,
  Register,
  Transaction,
} from "./pages";
import EmailEdit from "./pages/EmailTemplate/Edit";
import EmailTemplate from "./pages/EmailTemplate/Index";
import Privacy_policy from "./pages/Auth/PrivacyPolicy";
import ViewWallet from "./pages/User/Customer/ViewWallet";
import Markup from "./pages/Markup/Index";
import MarkupSGD from "./pages/MarkupSGD/Index";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={true} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/login" element={<SignIn />} />
      {/* <Route path="/sign-up" element={<Register />} /> */}
      <Route path="/privacy_policy" element={<Privacy_policy />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />

        {/* customer */}
        <Route exact path="/customer" element={<Customer />} />
        <Route exact path="/customer/view/:id" element={<CustomerView />} />
        <Route exact path="/customer/wallets/:id" element={<ViewWallet />} />

        {/* markup manager */}
        <Route exact path="/commission" element={<Markup />} />
        <Route exact path="/commission-sgd" element={<MarkupSGD />} />

        {/* cms manager */}
        <Route exact path="/cms" element={<CmsManagement />} />

        {/* notification */}
        <Route exact path="/notification" element={<Notifications />} />

        {/* email templates */}
        <Route exact path="/email-templates" element={<EmailTemplate />} />
        <Route exact path="/email-templates/:id/edit" element={<EmailEdit />} />

        {/* order */}
        <Route exact path="/order" element={<Order />} />

        {/* order */}
        <Route exact path="/transaction" element={<Transaction />} />
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
