import { Card, Col, DatePicker, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AppStateContext } from "../../context/AppContext";
import useRequest from "../../hooks/useRequest";
import { Avatar, List, Skeleton } from "antd";
import moment from "moment";
import apiPath from "../../constants/apiPath";


function Index() {
  const { setPageHeading } = useContext(AppStateContext);
  const [loading, setLoading] = useState();
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
  });

  const { request } = useRequest();

  const dates = {
    today: moment(new Date()).format("YYYY-MM-DD"),
    yesterday: moment(new Date().getTime() - 24 * 60 * 60 * 1000).format(
      "YYYY-MM-DD"
    ),
  };

  const fetchData = () => {
    request({
      url:
        apiPath.notification +
        `?status=${""}&page=${pagination ? pagination.current : 1}&pageSize=${
          pagination && pagination?.total ? pagination?.total : 10
        }&search=${""}`,
      method: "GET",
      onSuccess: (data) => {
        setList(data.data);
        setLoading(false);
      },
      onError: (err) => {
        console.log(err);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);
  
  useEffect(() => {
    setPageHeading("Notifications");
  }, []);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col span={24} md={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div
                className="notification-main-wrap"
                style={{ padding: "16px" }}
              >
                {loading ? (
                  <Skeleton />
                ) : (
                  <>
                    {list.length > 0 ? (
                      list.map((data, index) => {
                        console.log(data, "datadatadata");
                        return (
                          <div className="notification-card">
                            <h4>
                              {moment(data._id).format("YYYY-MM-DD") ===
                              dates.today
                                ? "Today's Notifications"
                                : moment(data._id).format("YYYY-MM-DD") ===
                                  dates.yesterday
                                ? "Yesterday's Notifications"
                                : moment(data._id).format("YYYY-MM-DD")}
                            </h4>
                            <List
                              key={"groupItem" + index}
                              itemLayout="horizontal"
                              dataSource={data.notifications}
                              renderItem={(item, index) => (
                                <List.Item className="list-notfy">
                                  <List.Item.Meta
                                    title={
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",gap: "5px"
                                        }}
                                      >
                                        <span className="tital-notfy">{item?.title}</span>
                                        <span className="dics-notfy">
                                          {moment(item?.created_at)?.format(
                                            "hh:mm A"
                                          )}
                                        </span>
                                      </div>
                                    }
                                    avatar={
                                      item?.data?.data?.user?.image ? (
                                        <Avatar
                                          src={item?.data?.data?.user?.image}
                                        />
                                      ) : (
                                        <Avatar
                                          style={{
                                            backgroundColor: "#00a2ae",
                                            verticalAlign: "middle",
                                          }}
                                          className="cap"
                                          size={50}
                                        >
                                          {" "}
                                          {item?.data?.data?.user?.name?.charAt(
                                            0
                                          )}{" "}
                                        </Avatar>
                                      )
                                    }
                                    description={item?.description}
                                  />
                                </List.Item>
                              )}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <h3>No Notification Found</h3>
                    )}
                  </>
                )}
              </div>

              {/* <div>
                <DatePicker onChange={(v)=>alert(v)} />
              </div> */}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
