import { Row, Col, Card, Table, Tabs, Select, Tooltip, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import apiPath from "../../../constants/apiPath";
import moment from "moment";
import TabPane from "antd/lib/tabs/TabPane";
import { currencies } from "../../../constants/conts";
import DeleteModal from "../../../components/DeleteModal";

function View() {
  const params = useParams();
  const location = useLocation();
  const { wallet } = location.state;
  const { request } = useRequest();
  const [orders, setOrders] = useState([]);
  const [transactions, setWalletTransactions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("order");
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(wallet?.currency);
  const [avgAmount, setAvgAmount] = useState(0);
  const [optionPosition, setOptionPosition] = useState(0);
  const [user, setUser] = useState();
  const [isClear, setIsClear] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [selected, setSelected] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [userData, setUserData] = useState({});

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      render: (_, { order_id }) => (
        <span color="white">#{order_id || "-"}</span>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, { quantity }) => {
        return <span>{quantity ?? 0} KG</span>;
      },
    },
    {
      title: "Quality",
      dataIndex: "quality",
      key: "quality",
      render: (_, { quality }) => {
        return <span>{quality ?? 0}</span>;
      },
    },
    {
      title: "Currency",
      dataIndex: "balance",
      key: "balance",
      render: (_, { currency }) => {
        return <span>{currency || "USD"}</span>;
      },
    },
    {
      title: `Booked Price`,
      dataIndex: "book_amount",
      key: "book_amount",
      render: (_, { book_amount }) => {
        return <span>{book_amount?.toFixed(2)}</span>;
      },
    },
    {
      title: `Total Amount`,
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => {
        return <span>{amount?.toFixed(2)}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        return <span className="text-uppercase">{status || "-"}</span>;
      },
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (_, { created_at }) => {
        return (
          <span>
            {moment(created_at).format("hh:mm A")},{" "}
            {moment(created_at).format("ll")}
          </span>
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            {!record?.clear_position ? (
              <Tooltip title={""} color={"purple"} key={""}>
                <Button
                  className="edit-cls btnStyle primary_btn"
                  onClick={() => {
                    setSelected(record);
                    setIsClear(true);
                  }}
                >
                  Clear
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title={""} color={"purple"} key={""}>
                <Button
                  className="delete-cls btnStyle primary_btn"
                  style={{width: "30px !important"}}
                  onClick={() => {
                    setSelected(record);
                    setIsReset(true);
                  }}
                >
                  Reset
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const deposits = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      key: "id",
      render: (_, { transaction_id }) => (
        <span color="white">#{transaction_id || "-"}</span>
      ),
    },
    {
      title: "Currency",
      dataIndex: "balance",
      key: "balance",
      render: (_, { currency }) => {
        return <span>{currency || "USD"}</span>;
      },
    },
    {
      title: `Transaction Amount`,
      dataIndex: "transaction_amount",
      key: "transaction_amount",
      render: (_, { transaction_amount }) => {
        return <span>{transaction_amount?.toFixed(2) || "-"}</span>;
      },
    },
    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      render: (_, { type }) => {
        return <span className="text-uppercase">{type || "-"}</span>;
      },
    },
    {
      title: "Order Id",
      dataIndex: "order_id",
      key: "order_id",
      render: (_, { order_id }) => {
        return order_id ? <span>#{order_id?.order_id}</span> : <span> - </span>;
      },
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (_, { created_at }) => {
        return (
          <span>
            {moment(created_at).format("hh:mm A")},{" "}
            {moment(created_at).format("ll")}
          </span>
        );
      },
    },
  ];

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  const fetchWalletTransaction = (id, currency) => {
    setLoading(true);
    request({
      url: apiPath.transactionList + `/${id}?currency=${currency}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data?.statusText == "true") {
          setWalletTransactions(data?.data?.data || []);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err?.error?.response?.message, Severty.ERROR);
      },
    });
  };

  const getOrders = (id, currency) => {
    setLoading(true);
    request({
      url: apiPath.orderList + `/${id}?currency=${currency}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data?.statusText == "true") {
          setOrders(data?.data?.data);
          setUserData(data?.data?.user);
          setAvgAmount(data?.data?.avgAmount[0]?.avgAmount ?? 0);
          setOptionPosition(data?.data?.avgAmount[0]?.openPosition ?? 0);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.error?.response?.message, Severty.ERROR);
      },
    });
  };

  // const getUser = () => {
  //   request({
  //     url: apiPath.listCustomer + `/${params.id}`,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       setUser(data?.data?.[0]);
  //     },
  //     onError: () => {},
  //   });
  // };

  useEffect(() => {
    console.log(wallet, "----wallet");
    console.log(user, "----user");
    // getUser();
    getOrders(params.id, currency);
    fetchWalletTransaction(params.id, currency);
  }, [params.id, currency, refresh]);

  const onClearPosition = (id) => {
    request({
      url: apiPath.clearPosition + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onResetPosition = (id) => {
    request({
      url: apiPath.resetPosition + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <Card className="mt-3  ">
        <div className="vls-head">
          <h4>Cap Limit: {userData?.cap || 0} (Kg)</h4>
          <h4
            style={{
              marginInline: 10,
              paddingInline: 10,
              border: "3px solid goldenrod",
              borderBlockEnd: "none",
              borderBlockStart: "none",
            }}
          >
            Open Position: {optionPosition?.toFixed(2) || 0} (Kg)
          </h4>
          <h4>
            Avg Order Amount:{" "}
            <span>
              {(avgAmount / 32.148)?.toFixed(2)} ({currency})
            </span>
          </h4>
        </div>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs={24} xl={24}>
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane className="tab-btn" tab="Order" key="order">
                  <div className="table-responsive customPagination">
                    <Table
                      className="ant-border-space main-table"
                      loading={loading}
                      columns={columns}
                      dataSource={orders}
                      pagination={true}
                    />
                  </div>
                </TabPane>

                <TabPane className="tab-btn" tab="Transactions" key="deposit">
                  <div className="table-responsive customPagination">
                    <Table
                      className="ant-border-space main-table"
                      loading={loading}
                      columns={deposits}
                      dataSource={transactions}
                      pagination={true}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Card>
      {isClear && (
        <DeleteModal
          reasons={[]}
          title={"Clear Position"}
          subtitle={`Are you sure you want to Clear this position?`}
          show={isClear}
          hide={() => {
            setIsClear(false);
            setSelected();
          }}
          onOk={() => onClearPosition(selected?._id)}
        />
      )}
      {isReset && (
        <DeleteModal
          reasons={[]}
          title={"Clear Position"}
          subtitle={`Are you sure you want to Reset this position?`}
          show={isReset}
          hide={() => {
            setIsReset(false);
            setSelected();
          }}
          onOk={() => onResetPosition(selected?._id)}
        />
      )}
    </>
  );
}

export default View;
