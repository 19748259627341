import React, { useEffect, useState } from "react";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import moment from "moment";
import useRequest from "../../hooks/useRequest";
import { Spinner } from "react-bootstrap";

const ChartGold = ({currency}) => {
  const options = { style: "currency", currency: currency };
  const numberFormat = new Intl.NumberFormat("en-US", options);
  const [data, setData] = useState([]);
  const { request } = useRequest();
  const [theme, setTheme] = useState("dark"); // Theme state

  const getVault = (text) => {
    request({
      url: `/common/dashboard/get-stock-price?type=${text}`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        const newData = data
          .map((item) => {
            const { data, created_at } = item;
            const price = data[currency];
            const timestamp = new Date(created_at).getTime();
            return [timestamp, price];
          }).sort((a, b) => a[0] - b[0]);
        setData(newData);

        console.log(newData, "Data");
      },
    });
  };

  useEffect(() => {
    getVault("2Y");
  }, [currency]);

  const handleRangeSelect = (event) => {
    const { rangeSelectorButton } = event;
    if (!rangeSelectorButton) return;
    getVault(rangeSelectorButton.text);
  };

  const configPrice = {
    chart: {
      height: 600,
      backgroundColor: theme === "dark" ? "#1A1A1A" : "#fff",
      style: {
        color: theme === "dark" ? "#fff" : "#000",
      },
      zooming: {
        type: "x",
      },
    },
    xAxis: {
      type: "datetime",
      
      events: {
        afterSetExtremes: handleRangeSelect,
      },
      labels: {
        style: {
          color: theme === "dark" ? "#fff" : "#000",
        },
      },
    },
    yAxis: [
      {
        offset: 20,
        labels: {
          formatter: function () {
            return numberFormat.format(this.value, 6);
          },
          x: -15,
          style: {
            color: theme === "dark" ? "#fff" : "#000",
            position: "absolute",
          },
          align: "left",
        },
      },
    ],
    legend: {
      enabled: false,
      itemStyle: {
        color: theme === "dark" ? "#0D0D0D" : "#000",
      },
    },
    // rangeSelector: {
    //   buttons: [
    //     {
    //       type: "minute",
    //       count: 1,
    //       text: "1M", 
    //     },
    //     {
    //       type: "minute",
    //       count: 5,
    //       text: "5M", 
    //     },
    //     {
    //       type: "day",
    //       count: 1,
    //       text: "1D",
    //     },
    //     {
    //       type: "day",
    //       count: 7,
    //       text: "1W",
    //     },
    //     {
    //       type: "month",
    //       count: 1,
    //       text: "1M",
    //     },
    //     {
    //       type: "year",
    //       count: 1,
    //       text: "1Y",
    //     },
    //   ],
    // },

    tooltip: {
      shared: true,
      formatter: function () {
        return (
          numberFormat.format(this.y, 0) +
          "</b><br/>" +
          moment(this.x).format("lll")
        );
      },
      style: {
        color: "#000",
      },
    },
    credits: {
      enabled: false,
    },

    series: [
      {
        name: "GOLD",
        data: data,
        color: theme === "dark" ? "#DAA520" : "#0071A7",
      },
    ],
  };

  return (
    <div>
      {/* <button onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}>
        Toggle Theme
      </button> */}
      <ReactHighcharts config={configPrice}> {<Spinner />} </ReactHighcharts>
    </div>
  );
};

export default ChartGold;
