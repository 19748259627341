import { Image, Menu, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import Logo from "../../assets/images/NewLogo.svg";
import Chat from "../../assets/images/side_nav/chat.svg";
import Cms from "../../assets/images/side_nav/cms.svg";
import Coust from "../../assets/images/side_nav/customer.svg";
import Dash from "../../assets/images/side_nav/dash.svg";
import File from "../../assets/images/side_nav/file.svg";
import Log from "../../assets/images/side_nav/log.svg";
import Bell from "../../assets/images/side_nav/notification.svg";
import Order from "../../assets/images/side_nav/order.svg";
import { AuthContext } from "../../context/AuthContext";
import DeleteModal from "../DeleteModal";
import { useAppContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";

export const menuItems = [
  {
    key: "dashboard-manager",
    path: "/dashboard",
    icon: Dash,
    label:"Dashboard"    // isShow: true,
  },
  {
    key: "customer-manager",
    label: "Customer Manager",
    icon: Coust,
    path: "/customer",
  },
  {
    key: "order-manager",
    label: "Order Manager",
    icon: Order,
    path: "/order",
  },
  {
    key: "transaction-manager",
    label: "Transaction Manager",
    icon: Order,
    path: "/transaction",
  },
  {
    key: "commission-manager",
    path: "/commission",
    icon: File,
    label: "Mark-up Manager (XAU)",
  },
  {
    key: "commission-sgd-manager",
    path: "/commission-sgd",
    icon: File,
    label: "Mark-up Manager (SGD)",
  },
  {
    key: "cms-manager",
    path: "/cms",
    icon: Cms,
    label: "CMS Manager",
  },
  {
    key: "email-template-manager",
    path: "/email-templates",
    icon: Chat,
    label: "Email Template Manager",
  },
  {
    key: "notifications",
    path: "/notification",
    icon: Bell,
    label: "Notifications",
  },
];

function Sidenav({ color }) {

  const { userProfile, logout, setUserProfile } = useContext(AuthContext);
  const { country } = useAppContext();

  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState("vertical");

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const showDeleteConfirm = (record) => {
    // logout();
    setIsLogoutModalVisible(true);
  };

  const renderTitle = (item) => {
    return (
      <>
        <Image preview={false} src={item.icon} />
        <span className="label">{item.label}</span>
      </>
    );
  };

  useEffect(() => {
    setLoading(true);
    if (!userProfile) return;
    if (userProfile.type == "Admin") {
      const items = menuItems;
      setMenuLinks(items);
      setLoading(false);
      return;
    }

    const newArray = menuItems.filter((item) => {
      if (item.isShow) {
        return true;
      } else {
        return userProfile.permission.includes(item.key);
      }
    });

    const links = newArray.filter((item) => {
      if (item?.children?.length) {
        return true;
      } else if (!item?.children) {
        return true;
      } else {
        return false;
      }
    });

    setMenuLinks(links);
    setLoading(false);
  }, [userProfile, country]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode("inline");
      } else {
        setMenuMode("vertical");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {loading ? (
        [1, 2, 3, 4, 5, 6].map((item) => <Skeleton active key={item} />)
      ) : (
        <>
          <div className="brand-logo">
            <NavLink to="/dashboard" className="imgOuter">
              <img className="" src={Logo} alt="" />
            </NavLink>
          </div>
          <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
            {menuLinks.map((item) => {
              if (item.children) {
                return (
                  <>
                    <Menu.SubMenu
                      className="vehicle-icon"
                      key={item.key}
                      title={
                        <>
                          <span className="icon">
                            <Image preview={false} src={item.icon} />
                          </span>
                          <span className="label">{item.label}</span>
                        </>
                      }
                    >
                      {item.children.map((child) => (
                        <Menu.Item key={child.key}>
                          <NavLink to={child.path}>{child.label}</NavLink>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  </>
                );
              }

              return (
                <Menu.Item key={item.key} disabled={item?.comming_soon}>
                  <NavLink to={item.path}>
                    {renderTitle(item)}&nbsp;
                    {item?.comming_soon ? "(Comming Soon)" : ""}
                  </NavLink>
                </Menu.Item>
              );
            })}

            <Menu.Item onClick={showDeleteConfirm}>
              <NavLink to={"#"}>
                <>
                  <Image preview={false} src={Log} />
                  <span className="label">Logout</span>
                </>
              </NavLink>
            </Menu.Item>
          </Menu>
        </>
      )}
      {isLogoutModalVisible && (
        <DeleteModal
          title={"Logout"}
          subtitle={`Are you sure you want to logout?`}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => {
            ShowToast("Logout Successfully!", Severty.SUCCESS);
            logout();
          }}
        />
      )}
    </>
  );
}

export default Sidenav;
