import { Button, Col, Form, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";

import DescriptionEditor from "../../components/DescriptionEditor";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import lang from "../../helper/langHelper";

const slug = {
  PRIVACY_POLICY: "privacy-policy",
  TERMS_AND_CONDITIONS: "terms-and-conditions",
  ABOUT_US: "about-us",
};

export const ContentManagerForm = () => {
  const [form] = Form.useForm();

  const { request } = useRequest();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [content, setContent] = useState();
  const [termDescription, setTermDescription] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const [aboutDescription, setAboutDescription] = useState("");

  const getContent = () => {
    request({
      url: apiPath.content,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.data);
        let privacyPolicy;
        let termsAndConditions;
        let aboutUs;

        data.data.forEach((item) => {
          if (item.slug === slug.PRIVACY_POLICY) {
            privacyPolicy = item;
          } else if (item.slug === slug.TERMS_AND_CONDITIONS) {
            termsAndConditions = item;
          } else if (item.slug === slug.ABOUT_US) {
            aboutUs = item;
          }

        });

        setContent({
          privacyPolicy,
          termsAndConditions,
          aboutUs
        });

        setTermDescription(termsAndConditions?.description);
        setPolicyDescription(privacyPolicy?.description);
        setAboutDescription(aboutUs?.description);

        setLoading(false);
        form.setFieldsValue({
          terms_and_conditions: termsAndConditions?.description,
          privacy_policy: privacyPolicy?.description,
          about_us: aboutUs?.description,
        });
      },
      onError: () => setLoading(false),
    });
  };

  const onFinish = (values) => {
    setSubmitting(true);
    let privacyPolicy = content?.privacyPolicy;
    let termsAndConditions = content?.termsAndConditions;
    let aboutUs = content?.aboutUs;

    const payload = [
      {
        name: termsAndConditions?.name,
        id: termsAndConditions?._id,
        description: values?.terms_and_conditions,
      },
      {
        name: privacyPolicy?.name,
        id: privacyPolicy?._id,
        description: values?.privacy_policy,
      },
      {
        name: aboutUs?.name,
        id: aboutUs?._id,
        description: values?.about_us,
      },
    ];

    console.log(payload);

    request({
      url: apiPath.content,
      method: "PUT",
      data: { content: payload },
      onSuccess: (data) => {
        setSubmitting(false);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (err) => {
        ShowToast(err, Severty.ERROR);
        setSubmitting(false);
      },
    });
  };

  const handleEditorChange = (data, type) => {
    switch (type) {
      case "term":
        return setTermDescription(data);
      case "policy":
        return setPolicyDescription(data);
      case "about":
        return setAboutDescription(data);
      default:
        return console.log("Unknown type");
    }
  };

  useEffect(() => {
    setLoading(true);
    getContent();
  }, []);

  return loading ? (
    [1, 2, , 3, 4, 5, 6].map((item) => <Skeleton active key={item} />)
  ) : (
    <Form form={form} onFinish={onFinish} className="cms-page">
      <Row gutter={24}>

        <Col md={12}>
          <h4>Page: About Trading</h4>
          <Form.Item
            name="about_us"
            rules={[
              {
                required: true,
                message: "Please Enter About Us Details!",
              },
            ]}
          >
            <DescriptionEditor
              value={aboutDescription}
              placeholder="Enter About Us"
              onChange={(data) => handleEditorChange(data, "about")}
            />
          </Form.Item>
        </Col>

        {/* <Col md={12}>
          <h4>Page: Terms And Conditions</h4>
          <Form.Item
            name="terms_and_conditions"
            rules={[
              {
                required: true,
                message: "Please Enter the Terms And Conditions!",
              },
            ]}
          >
            <DescriptionEditor
              value={termDescription}
              placeholder="Enter Terms And Conditions"
              onChange={(data) => handleEditorChange(data, "term")}
            />
          </Form.Item>
        </Col> */}
      </Row>

      {/* <Row gutter={24}>
        <Col md={12}>
          <h4>Page: Privacy Policy</h4>
          <Form.Item
            name="privacy_policy"
            rules={[
              {
                required: true,
                message: "Please Enter Privacy Policy!",
              },
            ]}
          >
            <DescriptionEditor
              value={policyDescription}
              placeholder="Enter Privacy Policy"
              onChange={(data) => handleEditorChange(data, "policy")}
            />
          </Form.Item>
        </Col>
      </Row> */}

      <Row>
        <Button
          loading={submitting}
          className="primary_btn btnStyle"
          htmlType="submit"
        >
          {lang("Save Changes")}
        </Button>
      </Row>
    </Form>
  );
};
